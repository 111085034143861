import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SectionTitle from "../components/sectiontitle";
import SEO from "../components/seo";

const Container = styled.div`
  display: grid;
  row-gap: 12px;
  justify-items: left;
  margin: 16px auto;
  max-width: 790px;
  padding: 8px;
  a {
    text-decoration: none;
    color: black;
  }
`

const Company = () => (
  <Layout>
    <SEO title="運営会社" keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
    <SectionTitle value="運営会社" button="記事一覧へ" visible="off" link="/" />
    <Container>
      <h2>
      スプラッシュトップ株式会社
      </h2>
      <p>
      所在地	〒100-0005<br/>
      東京都千代田区丸の内1-8-3<br/>
      1-8-3 Marunouchi, Chiyoda-Ku Tokyo 100-0005 Japan<br/>
      </p>
      <p>
        <a href="https://www.splashtop.co.jp/" target="_blank">https://www.splashtop.co.jp/</a>
      </p>
      <h2>
      アメリカ本社	Splashtop Inc.
      </h2>
      <p>
      所在地<br/>
      1054 S. De Anza Blvd, Suite 200 San Jose, CA 95129 U.S.A<br/>
      +1.408.861.1088<br/>
      </p>
      <p>
        <a href="https://www.splashtop.com/" target="_blank">https://www.splashtop.com/</a>
      </p>
      <p>
      事業内容	ソフトウェア開発
      </p>
      <p>
      オウンドメディアの運営・取材にご協力いただいた全ての皆さまへ<br/>
      心より感謝いたします。
      </p>
    </Container>
  </Layout>
)



export default Company